.input-login{
    padding: 0.5em 1em;
    font-size: 1.2em;
    outline: none;
    
    border: 1px solid lightgray;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.input:focus{
    border: 1px solid var(--sec-color);
}

