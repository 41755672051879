

.contact-us-section{
    margin: 5em 0;
    background: #f1f1f1;
}

.contact-txt-main{
    padding: 5em 3em;
    width: 40%;
    /* background: var(--border); */
}

.contact-us-section>div{
    height: 100%;
}

.contact-icon-div{
    border-radius: 50%;
    padding: 1em;
    background: lightgray;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.contact-icon{
    max-width: 2em !important;
    max-height: 2em !important;
}

.contact-map-div{
    width: 100%;
    /* height: 100%; */
}

@media only screen and (min-width:100px){
    .contact-txt-main{
        padding: 2em 3em;
        width: 100%;
    }

    .contact-map-div{
        width: 100%;
    }

    .contact-us-section>div{
        flex-direction: column;
    }

    .contact-map-div{
        width: 100%;
        /* min-height: 20em; */
    }

    .contact-us-section{
        margin: 2em 0em;
    }

}

@media only screen and (min-width:800px){
    .contact-txt-main{
        padding: 5em 3em;
        width: 50%;
    }

    .contact-map-div{
        width: 50%;
    }

    .contact-us-section>div{
        flex-direction: row;
    }
}

@media only screen and (min-width:1200px){
    .contact-txt-main{
        padding: 5em 3em;
        width: 50%;
    }
    .contact-map-div{
        width: 100%;
        min-height: auto;
    }

    .contact-us-section{
        margin: 3em 0em;
    }
}