
.checkboxes{
    gap: 0.1em
}

.checkboxDiv{
    /* position: relative; */
    min-width: 20%;
    
}

.checkboxDiv>.check-label{
    text-align: center;
    position: relative;
    padding: 0.5em 1.5em;
    background: transparent;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 .1em .1em rgba(0, 0, 0, 0.1);
}

.checkboxDiv>label>input[type="checkbox"] {
    position: absolute;
    inset: 0;
    opacity: 0;
}
