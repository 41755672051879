.popup-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    background: var(--transparent-dark);
    box-shadow: 0 0 10px 5px var(--transparent-dark);
    inset: 0;
    
}

.popup-inner-div{
    width: 100%;
    /* height: 90vh; */
    padding: 0.5em 1em;
    background: white;
    box-shadow: 0 0 15px 10px var(--transparent-dark);
    /* overflow: hidden; */
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.popup-header{
    justify-content: flex-end;
    padding: 0.2em 0.5em;
}

.popup-body{
    width: 100%;
    overflow-y: auto;
}

/* .popup-footer{
    width: 100%;
    min-height: 2em;
    height: fit-content;
    z-index: 999;
} */

@media screen and (min-width: 100px) {
    .popup-container{
        padding: 1em 1em;
    }

    .popup-body{
        overflow-y: auto;
        scroll-behavior: smooth;
    }
}

@media screen and (min-width: 700px) {
    .popup-container{
        padding: 2em 5em;
    }

    .popup-body{
        overflow-y: none;
        
    }
    
}


@media screen and (min-height: 100px){
    .popup-body{
        height: 80%;
    }
}

@media screen and (min-height: 230px){
    .popup-body{
        height: 85%;
    }
}

@media screen and (min-height: 300px){
    .popup-body{
        height: 90%;
    }
}

@media screen and (min-height: 560px){
    .popup-body{
        height: 95%;
    }
}

@media screen and (min-height: 900px){
    .popup-body{
        height: 95%;
    }
}