.option:first-child{
    color: var(--border) !important;
    opacity: 0.5;
}

.dropdown{
    width: 100%;
    padding: 0.5em 1em;
    font-size: 0.9em;
    color: grey;
    outline: none !important;
    background: var(--txt-color-light);
    border: 1px solid var(--border);
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.dropdown:disabled{
    background: #e8e4e4;
}

.dropdown:focus{
    border: 1px solid var(--sec-color);
    color: black;
}

.dropdown-lable{
    padding: 0.05em 0;
    font-size: 0.9em;
    z-index: 999;
}

.dropdown-box-span{
    width: 5em ;
    padding: 0.5em 1em;
    font-size: 0.9em;
    background: var(--border);
    color: var(--txt-color-dark);
    border-bottom-left-radius: 0.2em;
    border-top-left-radius: 0.2em;
}

.dropdown:focus .dropdown-box-span{
    background: var(--sec-color);
}


/* ===========================Material Dropdown===================================== */

.material-dropdown-div{
    width: 100%;
    position: relative;
}

.material-dropdown-lable{
    position: absolute;
    top: -0.9em;
    left: 0.8em;
    color: var(--sec-color);
    z-index: 99 !important;
    font-size: 0.9em;
    background: var(--txt-color-light);
    padding: 0.1em 0.2em;
}

.material-dropdown:disabled{
    background: rgb(232, 232, 232) !important;
}

.material-option-lable{
    position: absolute;
    color: grey;
    cursor: pointer;
    font-size: 0.9em;
    left: 1em;
    
    top: 0.9em;
    width: fit-content;
    height: fit-content;
    /* background: var(--txt-color-light); */
    /* padding: 0.1em 0.5em; */
}


.material-dropdown{
    width: 100%;
    padding: 1em 1em;
    font-size: 0.9em;
    outline: none;
    border: 1px solid var(--border);
    color: var(--txt-color-dark);
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.material-dropdown:focus{
    border: 1px solid var(--sec-color);
    color: black;
}