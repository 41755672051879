.form-section{
    box-shadow: none !important;
}

p{
    align-items: justify;
}

.btn-form{
    height: fit-content;
    min-width: 7em;
    padding: 0.55em 0.2em;

}

@media only screen and (min-width: 100px){
    .btn-form{
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 600px){
    .btn-form{
        font-size: 1em;
    }
}