.card{
    max-width: 24em;
    gap: 0.5em;
    background: var(--txt-color-light);
    padding: 1em 1.5em;
    text-align: justify;
    border-left: 3px solid var(--sec-color);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.card:hover{
    box-shadow: 0 0 1em 1px rgba(26, 139, 170, 0.5) inset;
}

.card p{
    height: 2.5em;
    overflow: hidden;
}

.card a{
    color: var(--sec-color);
}

.card h3{
    font-weight: 700;
}

@media only screen and (min-width: 100px) {
    .card{
        max-width: 100%;
    }
}

@media only screen and (min-width: 500px) {
    .card{
        max-width: 24em;
    }
}


/* ==================Card2 CSS==================================== */

.card2-section{
    /* padding: 5em 0; */

}

.card2-img-div{
    
}

.card2-img-card{
    max-width: 100%;
    min-width: 80%;
    position: relative;
    padding-top: 100%;
    box-shadow: var(--shadow);
    overflow: hidden;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
}

.card2-abs-div{
    position: absolute;
    inset: 0;
    
    /* z-index: ; */
}

.card2-abs-div>img{
    width: 100%;
    height: 100%;
}

.card2-name{
    width: 100%;
    padding: 0.5em;
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 1.5em;
}

.card2-name>div{
    position: absolute;
    inset: 0;
    
    filter: blur(8px);
    -webkit-filter: blur(8px);
    z-index: -1;
}