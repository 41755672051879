.header{
    width: 100%;
    /* height: 96px; */
    background: var(--sec-color);
    /* backdrop-filter: blur(8px); */
}

.inner-div{
    padding: 0.2em ;
    width: 90%;
}

.logo-div{
    color: var(--txt-color-light);
}

.nav-menu ul {
    gap: .2em;
    
}

.nav-menu ul li a{
    color: var(--txt-color-light);
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    border-radius: 0.1em;
    -webkit-border-radius: 0.1em;
    -moz-border-radius: 0.1em;
    -ms-border-radius: 0.1em;
    -o-border-radius: 0.1em;
}

.nav-menu ul li a:hover,
    .nav-menu ul li .active{
    background: var(--txt-color-light);
    color: var(--sec-color);
}
