.banner-container{
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/banner1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    position: relative;
}

.banner-back-abs{
    position: absolute;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 20%, transparent 50%);
    inset: 0;
    
}

.banner-inner{
    width: 90%;
    min-height: 55em;
    z-index: 99;
}

.banner-text{
    width: 40%;
}

.banner-text .high-txt{
    color: var(--sec-color);
    font-weight: bold;
}

.banner-text h2{
    font-size: 4em;
}

.banner-text p{
    font-size: 1.1em;
    text-align: justify;
}

.banner-card{
    position: relative;
    width: 60%;
    height: 100%;
}

.banner-card-abs{
    position: absolute;
    right: 0;
}

@media only screen and (min-width: 100px) {
    .banner-inner{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .banner-text{
        width: 100%;
        padding-top: 5em;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .banner-text h2{
        font-size: 2.5em;
    }

    .banner-text p{
        font-size: 1em;
    }

    .banner-card-abs{
        position: relative;
        padding-bottom: 2em;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        
    }

    .banner-container{
        background-size: cover;
        background-position: 50% 100%;
        position: relative;
    }

    .banner-back-abs{
        background: linear-gradient(to right, rgba(255, 255, 255, 0.6) ,rgba(255, 255, 255, 0.5));
        
    }

    .banner-card{
        width: 100%;
    }
}

@media only screen and (min-width: 500px) {
    .banner-inner{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .banner-text{
        width: 80%;
        padding-top: 5em;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .banner-text h2{
        font-size: 2.5em;
    }

    .banner-text p{
        font-size: 1em;
    }

    .banner-card-abs{
        position: relative;
        padding-bottom: 2em;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
    }

    .banner-container{
        background-size: cover;
        background-position: 50% 100%;
        position: relative;
    }

    .banner-back-abs{
        background: linear-gradient(to right, rgba(255, 255, 255, 0.6) ,rgba(255, 255, 255, 0.5));
        
    }

    .banner-card{
        width: 60%;
    }
}

@media only screen and (min-width: 768px) {
    .banner-text{
        width: 60%;
    }

    .banner-inner{
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .banner-text h2{
        font-size: 2.5em;
    }

    .banner-text p{
        font-size: 1em;
    }

    .banner-card-abs{
        position: absolute;
        right: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        
    }

    .banner-back-abs{
        position: absolute;
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 20%, transparent 50%);
        inset: 0;
        
    }

    .banner-container{
        width: 100%;
        height: 100%;
        background: url('../../../assets/images/banner1.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
        position: relative;
    }
}

@media only screen and (min-width: 850px) {
    .banner-text{
        width: 50%;
    }

    .banner-text h2{
        font-size: 3em;
    }

    .banner-text p{
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 1000px) {
    .banner-text{
        width: 50%;
    }

    .banner-text h2{
        font-size: 3.5em;
    }
}

@media only screen and (min-width: 1270px) {
    .banner-text h2{
        font-size: 4em;
    }

    .banner-text{
        width: 40%;
    }
}




/* ======================== Banner 2 CSS ============================================================= */

.banner2-section{
    padding: 5em 0;
    position: relative;

}

.banner2{
    position: absolute;
    margin-top: 7em;
    inset: 0;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.banner2-color,.banner2-color1{
    width: 40%;
    position: absolute;
    height: 1%;
    top: 95%;
}
.banner2-color{
    box-shadow: 0 0 80em 5em rgb(148, 182, 255);
    left: 10%;
}
.banner2-color1{
    box-shadow: 0 0 80em 5em rgb(153, 204, 255);
    right: 10%;
}

.banner2-div{
    position: relative;
    flex-direction: row-reverse;
    background: linear-gradient(to top, rgb(151, 203, 243), rgb(202, 234, 255));
    overflow: hidden;
}

.banner2-outer-div{
    z-index: 999;
    overflow: hidden;
    border-radius: 2em;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    -ms-border-radius: 2em;
    -o-border-radius: 2em;
}

.banner2-txt-div{
    max-width: 100%;
    height: 100%;
    padding: 5em 5em;
    position: relative;
}

.banner2-back-blur{
    position: absolute;
    inset: 0;
    z-index: -1;
}

.banner2-back-blur-txt{
    position: absolute;
    inset: 0;
    z-index: -1;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.banner2-img-div{
    justify-content: flex-end;
    position: relative;
    padding-top: 45.66%;
    width: 100%;

}

.banner2-abs-div{
    position: absolute;
    inset: 0;
}

.banner2-abs-div>img{
    max-width: 100%;
    max-height: 100%;
}

.banner2-back-blur>img{
    height: 100%;
    width: 100%;
}

.banner2-abs-coin{
    position: absolute;
    width: 5em;
    height: 5em;
    top: 40%;
    left: 80%;
    animation: coinRotation 10s infinite linear;
    -webkit-animation: coinRotation 10s infinite linear;
}

.banner2-abs-coin>img{
    max-width: 100%;
    max-height: 100%;
    
}

.banner2-bottom-content{
    background: white;
    width: 100%;
    padding: 2em;
}

@keyframes coinRotation {
    
    0%{
        /* top: 40%; */
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
}50%{
        /* top: 20%; */
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
}100%{
        /* top: 40%; */
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
}
}

@media only screen and (min-width: 100px){
    .banner2-color,.banner2-color1{
        top: 70%;
    }

    .banner2-section{
        padding: 0;
        
    }

    .banner2-outer-div{
        width: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .banner2-div{
        flex-direction: column;
    }

    .banner2-img-div{
        padding-top: 100%;
        width: 100%;
    }

    .banner2-abs-div{
        width: 100%;
        display: flex;
        justify-content: center;

    }

    .banner2-abs-coin{
        position: absolute;
        width: 4em;
        height: 4em;
        top: 30%;
        left: 75%;
        animation: coinRotation 10s infinite linear;
        -webkit-animation: coinRotation 10s infinite linear;
    }

    .banner2-bottom-content{
        display: none;
    }

    .banner2-txt-div{
        padding: 3em 1em;
        
    }

    .banner2-txt-div>div>h2{
        text-align: left !important;
    }
}

@media only screen and (min-width: 400px){
    .banner2-abs-coin{
        position: absolute;
        width: 4em;
        height: 4em;
        top: 35%;
        left: 80%;
        animation: coinRotation 10s infinite linear;
        -webkit-animation: coinRotation 10s infinite linear;
    }

    .banner2-color,.banner2-color1{
        top: 70%;
    }

}

@media only screen and (min-width: 500px){

    .banner2-color,.banner2-color1{
        top: 90%;
    }
}

@media only screen and (min-width: 600px){
    .banner2-abs-coin{
        position: absolute;
        width: 5em;
        height: 5em;
        top: 40%;
        left: 80%;
        animation: coinRotation 10s infinite linear;
        -webkit-animation: coinRotation 10s infinite linear;
    }
}


@media only screen and (min-width: 800px){
    .banner2-color,.banner2-color1{
        top: 70%;
    }
    
    .banner2-txt-div{
        max-width: 100%;
        height: 100%;
        padding: 5em 1.5em;
        text-align: center !important;
        position: relative;
    }

    .banner2-section{
        padding: 5em 0;
    }

    .banner2-outer-div{
        width: 90%;
        border-radius: 2em;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        -ms-border-radius: 2em;
        -o-border-radius: 2em;
    }

    .banner2-div{
        flex-direction: row-reverse;
    }

    .banner2-img-div{
        padding-top: 52.66% !important;
        width: 100%;
    }

    .banner2-abs-div{
        width: 100%;
        display: flex;
        

    }

    .banner2-abs-coin{
        position: absolute;
        width: 4em;
        height: 4em;
        top: 35%;
        left: 80%;
        animation: coinRotation 10s infinite linear;
        -webkit-animation: coinRotation 10s infinite linear;
    }

    .banner2-bottom-content{
        display: flex;
    }
}

@media only screen and (min-width: 930px){
    .banner2-img-div{
        padding-top: 49.66 !important;
    }
}


@media only screen and (min-width: 1050px){
    .banner2-color,.banner2-color1{
        top: 75%;
    }
    .banner2-txt-div{
        max-width: 100%;
        height: 100%;
        padding: 5em 5em;
        text-align: center !important;
        position: relative;
    }
}

@media only screen and (min-width: 1200px){
    .banner2-color,.banner2-color1{
        top: 80%;
    }

    .banner2-section>div{
        width: 80%;
    }

    .banner2-img-div{
        padding-top: 55% !important;
    }

    .banner2-abs-coin{
        position: absolute;
        width: 5em;
        height: 5em;
        top: 35%;
        left: 80%;
        animation: coinRotation 10s infinite linear;
        -webkit-animation: coinRotation 10s infinite linear;
    }
    
}

@media only screen and (min-width: 1220px){
    .banner2-img-div{
        padding-top: 49% !important;
    }
    
}


@media only screen and (min-width: 1265px){
    .banner2-img-div{
        padding-top: 47% !important;
    }
    
}



@media only screen and (min-width: 1324px){
    .banner2-img-div{
        padding-top: 45% !important;
    }
    
}

@media only screen and (min-width: 1400px){
    .banner2-color,.banner2-color1{
        top: 85%;
    }
    
}

@media only screen and (min-width: 1450px){
    .banner2-img-div{
        padding-top: 45.66% !important;

    }
}

@media only screen and (min-width: 1500px){
    .banner2-color,.banner2-color1{
        top: 88%;
    }
    
}

@media only screen and (min-width: 1630px){
    .banner2-color,.banner2-color1{
        top: 95%;
    }
}