.howItWorks-section{
    padding: 5em 0em;
    margin-bottom: 5em;
}

/* .howItWorks-content-div{

} */

.howItWorks-heading>h2{
    color: var(--grey);
}

.howItWorks-heading>p{
    text-align: center;
    width: 60%;
    font-size: 0.99em !important;
    color: rgba(128, 128, 128, 0.797) !important;
    margin-bottom: 4em;
}

/* .howItWorks-card{
    width: 100%;
} */

.howItWorks-svg-div{
    position: relative;
    width: 100%;
    min-height: 10em;

}

.howItWorks-anim-div,.howItWorks-anim-div2{
    position: absolute;
    height: 16em;
    z-index: -999;
    opacity: .4;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.howItWorks-anim-div{
    left: 2em;
    right: 50%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 0;
}

.howItWorks-anim-div2{
    right: 10em;
    left: 50%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 100%;
    /* background: rgba(255, 247, 89, 0.306); */
}

.howItWorks-index{
    font-size: 5em;
    color: lightgray;
}

.howItWorks-anim-card{
    width: 60%;
    box-shadow: var(--shadow);
    padding: 1em 2em;
    z-index: 100;
    background: white;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.howItWorks-anim-card>h3{
    text-align: center;
}

.howItWorks-input-div{
    width: 100%;
    gap: 0.2em;
    border: 1px solid var(--border);
    background: white;
    padding: 0;
    height: 3.5em;
    text-align: center;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.howItWorks-input-div input{
    outline: none !important;
    border: none !important;
    border-right: 1px solid var(--border) !important;
}

.howItWorks-input-div>img{
    width: 2.5em;
    height: 2.5em;
    padding: 0.2em;
}

.txtAmin{
    width: 100%;
}

.txtAmin>span{
    height: 0.6em;
    background: rgb(239, 239, 239);
}

.howItWorks-emoji>img{
    width: 4em;
    
}

.howItWorks-txt-div>div{
    text-align: justify;
    
}

.howItWorks-content{
    gap: 10em;
}

@media only screen and (min-width: 100px) {
    .howItWorks-card{
        gap: 2em;
    }

    .howItWorks-content-div{
        width: 100%;
        padding: 0 0.5em;
    }

    .howItWorks-emoji{
        gap: 1em !important;
        
    }

    .howItWorks-card{
        flex-direction: column;
    }

    .howItWorks-svg-div{
        gap: 0.5em !important;
    }
}

@media only screen and (min-width: 800px){
    .howItWorks-card{
        flex-direction: row;
    }

    .howItWorks-svg-div{
        gap: 2em !important;
    }
}


@media only screen and (min-width: 1000px) {
    .howItWorks-card{
        gap: 2em;
    }

    .howItWorks-content-div{
        width: 90%;
    }

    .howItWorks-emoji{
        gap: 3em !important;
        
    }
}

@media only screen and (min-width: 1200px) {
    .howItWorks-card{
        gap: 5em;
    }

    .howItWorks-content-div{
        width: 80%;
    }
}