


@media only screen and (min-width: 100px){
    #heading{
        flex-direction: column;    
    }
    #heading>h3{
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 400px){
    #heading>h3{
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 790px){
    #heading{
        flex-direction: row;    
    }
}