.softInfo-section{
    padding: 5em 0;
}

.softInfo-img-div{
    position: relative;
    padding-top: 66.66%;
}

.soft-img-div{
    position: absolute;
    inset: 0;

}

.soft-img-div>img{
    max-width: 100%;
    max-height: 100%;
    /* box-shadow: 0 0 3em 1em rgba(0, 0, 0, 0.2); */

}

@media only screen and (min-width: 100px){
    .soft-img-div{
        justify-content: center;
    }

    .softInfo-section{
        width: 98%;
    }
}

@media only screen and (min-width: 800px){
    .soft-img-div{
        justify-content: flex-start;
    }

}

@media only screen and (min-width: 1200px){
    .softInfo-section{
        width: 80%;
    }
}

