
.footer-section{
    padding: 5em;
    justify-content: center;
    background: var(--grey);
    color: var(--txt-color-light);
}

.footer-card>h3{
    font-size: 1.5em;
}

.footer-ul{
    gap: 1em;
}

.footer-ul>li>a{
    color: var(--border);
    font-size: 1em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.footer-ul>li>a:hover{
    color: var(--txt-color-light);
}

@media only screen and (min-width:100px){
    .footer-card{
        width: 100%;
        min-width: 20%;
    }

    .footer-section{
        flex-wrap: wrap;
    }


}

@media only screen and (min-width:500px){
    .footer-card{
        width: auto;
        max-width: 50%;
    }

    .footer-section{
        flex-wrap: wrap;
    }


}

@media only screen and (min-width:832px){
    .footer-card{
        width: 100%;
    }

    .footer-section{
        flex-wrap: nowrap;
    }
}

@media only screen and (min-width:1200px){
    .footer-card{
        width: fit-content;
    }
}