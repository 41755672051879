
.form-section{
    width: 100%;
    height: 100%;
    background: white;
    padding: 2em;
    position: relative;
    overflow: hidden;
    box-shadow: var(--shadow);
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.form-header{
    width: 100%;
    position: relative;
    overflow: hidden !important;
}

.form-search{
    position: fixed;
    top: 4em;
    left: 8em;
    right: 8em;
    background: white;
    z-index: 999;
}

.form-header>input{
    width: 100%;
    box-shadow: var(--shadow);
}

.form-cards{
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
}

.form-card-div{
    /* border: 2px solid rgba(51, 184, 201, 0.15); */
    width: 30%;
    min-height: 5em;
    height: 7em;
}


.form-card-div>a{
    width: 100%;
    height: 100%;
    padding:3px;
    background: var(--txt-color-light);
    color: var(--theme-c-dark);
    cursor: pointer;
    /* box-shadow: 0 0 30px 2px rgba(51, 184, 201, 0.15) inset; */
    
    text-align: center;
    background:var(--theme-c-light);
    transition: all 0.2s ease-in-out;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    /* border:4px solid transparent; */
    border-bottom: 2px solid var(---sec-color);
}

.form-card-div>a:hover{
    box-shadow: 0 0 10px -5px darkgray;
    /* box-shadow: var(--shadow); */
    /* border:4px solid var(--theme-c-light); */
    border-bottom: 4px solid var(---sec-color);
    transform: scale(1.003);
    -webkit-transform: scale(1.003);
    -moz-transform: scale(1.003);
    -ms-transform: scale(1.003);
    -o-transform: scale(1.003);
}

.form-card-div>.heading{
    width: 100%;
    height: 50%;
    /* padding: 0.5em; */
    font-size: 1.1em;
    text-align: center;
    /* border-right: 2px solid var(--border-color);  */
}

.form-card-div>.discription{
    width: 100%;
    /* height: 50%; */
    /* padding: 0.5em 0.5em; */
    /* font-size: 0.9em; */
    text-align: center;
}

.discription>p{
    font-size: 0.9em;
}

@media only screen and (min-width: 100px){

    .form-section{
        padding: 2em 0.5em;
    }

    .form-card-div>.heading{
        font-size: 1.2em;
    }

    .form-header{
        flex-direction: row;
        gap: 0.5em;
    }

    .form-cards{
        padding: 1em 0em;
        
    }

    .form-card-div{
        min-width: 100% ;
        height: auto;
    }

    .form-search{
        left: 2em;
        right: 2em;
        top: 3em;
    }
}

@media only screen and (min-width: 400px){
    .form-card-div>.heading{
        font-size: 1.2em;
    }

    .form-cards{
        padding: 1em 0em;
    }

    .form-card-div{
        min-width: 100% ;
    }

    .btn-form{
        width: fit-content !important;

    }
}

@media only screen and (min-width: 700px){
    .form-section{
        padding: 2em;
    }
    
    .form-header{
        flex-direction: row;
        gap: 1em;
    }

    .form-card-div>.heading{
        font-size: 1em;
    }

    .form-cards{
        padding: 1em 0.5em;
        
    }

    .form-card-div{
        min-width: 48% ;
        height: 8em;
    }

    .form-search{
        top: 4em;
        left: 8em;
        right: 8em;
    
    }
}


@media only screen and (min-width: 900px){
    .form-card-div>.heading{
        font-size: 1em;
    }

    .form-cards{
        padding: 1em 0.5em;
    }
}

@media only screen and (min-width: 1200px){
    .form-card-div{
        min-width: 30%;
        /* max-width: 25%; */
    }
}

@media only screen and (min-width: 1600px){
    .form-card-div>.heading{
        font-size: 1.1em;
    }

    /* .form-card-div{
        min-width: 15%;
        max-width: 15%;
    } */
}

