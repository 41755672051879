.faq-section{
    padding: 5em;
}

.faq-form-div{
    gap: 2em;
}

@media only screen and (min-width: 100px){
    .faq-div{
        width: 100%;
    }

    .faq-section{
        padding: 2em 2em;
    }
}

@media only screen and (min-width: 800px){
    .faq-div{
        width: 80%;
    }

    .faq-section{
        padding: 5em;
    }
}

@media only screen and (min-width: 1200px){
    .faq-div{
        width: 50%;
    }
}