
.checkbox-div{
    width: 100%;
}

.checkbox-input{
    width: fit-content;
    /* padding: 1em; */
}

.checkbox-lable{
    cursor: pointer;
}

.checkbox-input:disabled{
    background: #e8e4e4;
}