
.support-section{
    background: var(--txt-color-light);
    padding: 5em 0em;
    /* box-shadow: var(--shadow); */
}

.support-svg-div{
    height: fit-content;
}

.support-svg-div>div>div{
    height: 100%;
    width: fit-content;
    min-width: 2em;
}

.support-icon{
    width: 2em;
    height: 2em;
}

.support-card h4{
    color: var(--grey);
}

.support-card p{
    text-align: justify;
}


@media only screen and (min-width: 100px){
    .support-section{
        padding: 2em 1em;
    }

    .support-svg-div{
        flex-wrap: wrap;
        width: 98%;
    }

    .support-card {
        border: 1px solid var(--border);
        padding: 2em;
        flex-direction: column;
        z-index: 999;
    }
    .support-card>p{
        font-size: 0.8em !important;
    }
}

@media only screen and (min-width: 500px){
    .support-section{
        padding: 5em 2.5em;
    }

    .support-card{
        flex-direction: row;
        background: transparent;

    }
}

@media only screen and (min-width: 800px){
    .support-section{
        padding: 5em 2.5em;
    }

    .support-svg-div{
        flex-wrap: nowrap;
    }

    .support-card{
        border: none;
        padding: 2em;

    }
}

@media only screen and (min-width: 1200px){
    .support-section{
        padding: 5em 0em;
    }

    .support-svg-div{
        width: 80%;
    }
}