.radio-btn-div{
    overflow: hidden;
    position: relative;
    height: fit-content;
}

input[type="radio"]{
    position: relative;
    cursor: pointer;
}

.radio-btn-div>.radio-label{
    cursor: pointer;
    
}

