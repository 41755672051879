@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f1f1f1; */
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Bellefair', serif; */
  text-decoration: none;
  list-style: none;
}

html{
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: Gilroy, sans-serif; */
  font-family: Figtree, sans-serif;
  
}

::placeholder{
  color: gray;
  font-size: 0.9em;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --primary-color: #0aa399;
  --primary-color-light: #ef3f4910;

  /* --sec-color: #30D5C8; */
   /* --sec-color: rgb(13, 137, 240); */
   /* --sec-color: rgb(151, 203, 243); */
   --sec-color: rgb(26, 139, 170);
   --sec-color-light: rgba(26, 139, 170,0.8);
   --theme-c-light: rgb(234,234,234);
   --theme-c-dark: rgb(75,80,90);
  
   /* --sec-color: rgb(47, 60, 126); */
  /* --sec-color: #0aa399; */

  --grey: rgb(64, 71, 83);

  --border-color: #f1f1f1;
  --border: lightgray;
  /* --border: rgb(250, 233, 235); */

  --shadow: 0 0 15px 5px rgba(0,0,0,.1);
  --shadow-sec: 0 0 10px 1px #60D5C880;

  --btn-pri: rgb(0, 163, 108);
  --btn-pri-light: rgba(0, 163, 108,0.9);
  --btn-sec: rgb(0, 71, 171);
  --btn-sec-light: rgba(0, 71, 171.0.9);


  /* --bg-light: #30D5C8; */

  --txt-color-light: white;
  --txt-color-dark: rgb(21, 39, 51);

  --transparent-dark: rgba(0,0,0,0.2);
  --transparent-light: rgba(255,255,255,0.2);
  /* --transparent-light: rgba(171, 150, 219,0.1); */
}

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  background: var(--border-color);
  border-radius: 0.2em;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  -ms-border-radius: 0.2em;
  -o-border-radius: 0.2em;
}
::-webkit-scrollbar-thumb {
  background: var(--border);
  border-radius: 0.2em;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  -ms-border-radius: 0.2em;
  -o-border-radius: 0.2em;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 187, 187);
}

.h2-txt{
  font-size: 3em;
  font-family: Gilroy, sans-serif;
  color: var(--grey);
}

.p-txt{
  font-size: 1.2em;
}


.flex{
  display: flex;
}

.flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column{
  flex-direction: column;
}

.flex-row{
  flex-direction: row !important;
}

.gap-1{
  gap: 1em;
}

.gap-2{
  gap: 2em;
}

.gap-3{
  gap: 3em;
}

.gap-4{
  gap: 4em;
}

.gap-5{
  gap: 5em;
}

.m-1{
  margin: 1em;
}

.mt-1{
  margin-top: 1em;
}

.mt-2{
  margin-top: 2em;
}

.mt-3{
  margin-top: 3em;
}

.mt-4{
  margin-top: 4em;
}

.mt-5{
  margin-top: 5em;
}

.ml-2{
  margin-left: 2em;
}

.ml-3{
  margin-left: 3em;
}

.ml-4{
  margin-left: 4em;
}

.ml-5{
  margin-left: 5em;
}

.w-80{
  width: 80%;
}

.w-100{
  width: 100%;
}

.w-90{
  width: 90%;
}

.w-95{
  width: 95%;
}

.w-50{
  width: 50%;
}

.w-20{
  width: 20%;
}

.w-10{
  width: 10%;
}

hr{
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.2;
  /* box-shadow: 0 0 30px 5px rgba(51, 184, 201, 0.15); */
}

.multi-form-tabs-list{
 display: flex;
 gap: 5px;
}
.multi-form-tabs-list li{
  background: lightgray;
  color:'pink';
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid transparent
}

.multi-form-tabs-list li>.label{
  padding: 5px;
}

.multi-form-tabs-list li>.icon{
  padding: 5px;
}

.multi-form-tabs-list li.active{
  color: 'white';
  border:1px solid black
}

@media only screen and (min-width: 100px){
  .p-txt{
    font-size: 0.9em !important;
    text-align: justify;
  }

  .h2-txt{
      font-size: 1.5em !important;
      text-align: center;
  }
}

@media only screen and (min-width: 800px){
  .p-txt{
      font-size: 1em !important;
  }

  .h2-txt{
      font-size: 2em !important;
      text-align: left;
  }
}

@media only screen and (min-width: 1200px){
  .h2-txt{
    font-size: 3em !important;
  }

  .p-txt{
      font-size: 1.2em !important;
  }
}


