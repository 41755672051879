.lgn{
    height: 100vh;
    display: flex;
}
.lgn-left,
.lgn-right
{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lgn-left{
    border-right: 1px solid lightgrey;
}

.lgn-left .img-cover{
    width: 200px;
}

.lgn-left .img-cover img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.login{

    width: 90%;
    max-width: 400px;
}
.login h3{
    text-align: center;
    font-size: 1.5em;
    font-weight: bolder;
}
.login h4{
    text-align: center;
    font-weight: normal;
    font-size: 1.1em;
}

.login label{
    font-weight: bolder;
}

.login form {

    display: flex;
    flex-direction: column;
}
.login .f-item{
     margin:10px auto;
     width:100%;
}

.login .actions{
    display: flex;
    justify-content: center;
    width:100%;
}
.login .check-n-link{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.login .check-with-label{
    display: flex;
    align-items: center;
}

.login .check-with-label label{
    line-height: 30px;
    font-weight: normal !important;
    margin-left:4px;
}

