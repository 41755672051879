.input{
    padding: 0.5em 1em;
    font-size: 0.9em;
    outline: none;
    border: 1px solid var(--border);
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
}

.input-lable{
    padding: 0em 0;
    font-size: 0.9em;
}

.error-txt{
    position: absolute;
    bottom: -1.2em;
}

.input:focus{
    border: 1px solid var(--sec-color);
}
.input:focus> .input-box-span{
    background: var(--sec-color) !important;
    color: white;
}
input[type="month"],input[type="date"]{
    padding: 0.68em 1em;
}


input[type="radio"]{
    width: fit-content;
    margin-right: 0.5em;
}

.input-div{
    position: relative;
}

.input-box-span{
    width: 5em ;
    padding: 0.5em 1em;
    font-size: 0.9em;
    background: var(--border);
    color: var(--txt-color-dark);
    border-bottom-left-radius: 0.2em;
    border-top-left-radius: 0.2em;
}

.input-div input[type="text"]:disabled {
    background:#e8e4e4
  }
/* ======================== Material Text ================================= */

.material-input{
    min-width: 100% !important;
    padding: 1em 1em;
    font-size: 0.9em;
    outline: none;
    border: 1px solid var(--border);
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.material-input:focus::placeholder{
    color: white;
}
.material-input:focus{
    border: 1px solid var(--sec-color);
}

.material-box-span{
    padding: 0em 0.3em;
    /* width: 5em; */
    position: absolute;
    left: 0.8em;
    top: -0.7em;
    font-size: 0.8em;
    background: white;
}

.material-input:focus .material-box-span{
    color: var(--sec-color) !important;
}



/* ======================Search Input Div================================= */

.search-input{
    min-width: 100% !important;
}