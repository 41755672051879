.btn{
    /* min-width: 8.5em;
    padding: 0.6em 2em; */
    background: var(--primary-color);
    outline: none;
    border: none;
    text-transform: uppercase;
    color: var(--txt-color-light);
    /* font-size: 0.9em; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }
  
  .btn:hover{
    background: var(--primary-color-light);
  }

  .btn[varient="contained"]{
    background: var(--btn-pri);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    border-radius:0.2em ;
    -moz-border-radius:0.2em ;
    -ms-border-radius:0.2em ;
    -o-border-radius:0.2em ;
    -webkit-border-radius:0.2em ;
}

  .btn[varient="contained"]:hover{
    background: var(--btn-pri-light);
  }
  
  .btn[varient="outlined"]{
    background:white !important;
    border:1px solid var(--primary-color);
    color: var(--primary-color);
  }

  .btn[varient="outlined"]:hover{
    background:var(--primary-color) !important;
    border:1px solid var(--primary-color);
    color: white;
  }

  .btn[varient="text"]{
    background:none !important;
    border: none;
    color: black;
    padding: 0;
    margin: 0;
    min-width: fit-content !important;
    text-decoration: none !important;
  }

  .btn[varient="text"]:hover{
    color: var(--primary-color);
  }
