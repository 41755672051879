.prv-itm{
    display: flex;
}

.prv-itm div{
    border-bottom: 1px solid lightgrey
}

.prv-itm div:nth-child(1){
    width: 50%;
    flex-grow: 1;
}

.prv-itm div:nth-child(2){
    min-width: 100px;
    text-align:right;
    font-weight: bold;
}

.prv-itm div:nth-child(3){
    min-width: 100px;
    text-align: right;
    font-weight: bolder;

}