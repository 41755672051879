.material-container-main{
    /* padding: 0.5em; */
    margin: 0.5em 0em;
}

.material-container-div{
    border: 1px solid var(--border);
    padding: 1em;
    position: relative;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.sub-Heading{
    position: absolute;
    top: -0.85em;
    left: 0.7em;
    padding: 0.1em 0.4em;
    color: grey;
    font-weight: 600;
    background: var(--txt-color-light);
}

.material-container-body{

}