.about-section{
    gap: 2em;
    padding: 5em 5em;
}

.about-img-div, .about-txt-div{
    width: 49%;
    position: relative;
}

.about-img-div>.img-div{
    padding-top: 88.88%;
    overflow: hidden;
}

.about-img-abs-div{
    position: absolute;
    inset: 0;
    
}

.about-img-abs-div>img{
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0em 1.5em 2em 0em rgba(0,0,0,0.4);
}

.about-txt-div{
    gap: 2em;
    padding: 0em 3em;
}

.txt-heading>h2{
    font-size: 3em;
}

.about-txt-div>p{
    text-align: justify;
    margin-top: 0;
    font-size: 1em;
}

.about-svg-container>div{
    width: 45%;
    padding: 2em;
    z-index: 99;
    background: var(--txt-color-light);
    /* box-shadow: var(--shadow); */
    border: 1px solid var(--border);
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
}

.about-svg-container{
    justify-content: flex-end;
    /* filter: blur(8px);
    -webkit-filter: blur(8px); */
}

.about-svg-div{
    justify-content: flex-end;
    padding: 2em 0;
}

.icon-div{
    padding: 2em;
    max-width: 8em;
    max-height: 8em;
}

@media only screen and (min-width: 100px){
    .about-section{
        flex-direction: column-reverse;
        padding: 2em 2em;
    }

    .about-img-div, .about-txt-div{
        width: 100%;
    }

    .about-img-div>.img-div{
        padding-top: 66.66%;
    }

    .about-txt-div{
        gap: 1em;
        padding: 0em 0em;
    }

    .about3Section>div {
        flex-direction: column;
    }
}

@media only screen and (min-width: 800px){
    .about-section{
        flex-direction: row-reverse;
        padding: 5em 2em;
    }

    .about-img-div, .about-txt-div{
        width: 100%;
    }

    .about-img-div>.img-div{
        padding-top: 66.66%;
    }

    .about-txt-div{
        gap: 1em;
        padding: 0em 0em;
    }

    
}

@media only screen and (min-width: 1200px){
    .about-section{
        flex-direction: row-reverse;
        
    }

    .about-txt-div{
        gap: 2em;
        padding: 0em 3em;
    }

    .about3Section>div {
        flex-direction: row;
    }
}

@media only screen and (min-width: 1400px){
    
}


/* ====================== About2 CSS Starts ====================================== */

.about2-section{
    padding: 5em 0;
}

.about2-img-div{
    width: 100;
}

