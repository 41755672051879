.layout{
    width: 100%;
    background: var(--sec-color);
}

.header{
    width: 100%;
    box-shadow: 0 0.2em 0.5em 0 rgba(0, 0, 0, 0.1);
}

.body{
    width: 80%;
    margin: 0 auto;
    padding: 2em;
    
}

.body>ul{
    padding: 0em 0;
    margin-bottom: 2.5em;
    
}

.body>ul>li>a{
    color: var(--sec-color);
    padding: 0.5em 1em;
    border-radius: 0.15em;
    -webkit-border-radius: 0.15em;
    -moz-border-radius: 0.15em;
    -ms-border-radius: 0.15em;
    -o-border-radius: 0.15em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.body>ul>li>.active,.body>ul>li>a:hover{
    background: var(--sec-color);
    color: var(--txt-color-light);
    box-shadow: var(--shadow);
}

@media only screen and (min-width:100px){
    .body{
        width: 100%;
        margin: 0 auto;
        padding: 0em 0.2em;
        padding-bottom: 1em;
        
    }

    .body>ul{
        flex-direction: row;
        background: var(--txt-color-light);
        justify-content: space-between;
        /* padding: 0 1em; */
    }

    .body>ul>li{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .body>ul>li>a{
        width: 100%;
        text-align: center;
        padding: 0.5em;
        font-size: 0.8em;
    }
}

@media only screen and (min-width:400px){
    .body{
        width: 95%;
        margin: 0 auto;
        padding-bottom: 1em;
        
    }
}

@media only screen and (min-width:560px){
    .body>ul>li>a{
        font-size: 1em;
    }
}

@media only screen and (min-width:700px){
    .body>ul{
        flex-direction: row;
        gap: 0.2em;
        max-width: 1000px;
        /* justify-content: center;
        background: none; */
    }

    .body>ul>li{
        /* width: fit-content; */
        display: flex;
    }

    .body>ul>li>a{
        width: 100%;
        text-align: center;
        padding: 0.5 1em;
        /* border: 1px solid var(--sec-color); */

    }
}

@media only screen and (min-width:900px){
    /* .body{
        width: 90%;
        margin: 0 auto;
        padding: 1em 0em;
        
    } */
}

@media only screen and (min-width:1600px){
    .body{
        width: 85%;
        margin: 0 auto;
        padding-bottom: 1em;
        
    }
}