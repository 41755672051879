

.form-container{
    width: 100%;
}

p{
    text-align: justify;
}

.form-inputs{
    width: 100%;
}

.form-group>h1{
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: 1.5em;
}

.form-group>h1>span{
    font-size: 1.5em;
    margin: 0 0.5em;
}

.input-div{
    width: 100%;
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.table-div{
    justify-content: flex-end;
    align-items: flex-end;
    
}

.input-div>.table-txt>p{
    min-width: 41%;
    text-align: right;
}

.form-group-div{
    gap: 1em;
}

.input-div>div>div>p>span{
    width: 100%;
    text-align: right;
}

.form-group-div>.input-div>input{
    width: 100% !important;
}


.table-date{
    min-width: 41%;
}

.table-date>input{
    width: fit-content;
}

.form-group-div>.input-div>input:focus .input-div>p{
    color: var(--sec-color) !important;
}

.btn-div{
    padding: 1em 0;
}

.error-div{
    color: red;
    font-weight: bold;
}

.F_T4RIF{
    width: 100%;
}

.input-div>.table-txt{
    min-width: 41%;
    /* justify-content: flex-end; */
}

.table-txt-ttl{
    margin-bottom: -0.5em !important;
}

@media screen and (min-width: 100px){
    .table-div,.table-head{
        flex-wrap: wrap;
    }
    .table-head-txt{
        display: flex;
    }
    .table-head{
        display: none;
    }

    .table-txt-total{
        display: none;
    }

    .form-group>h1{
        flex-direction: column;
        text-align: center;
        gap: 0.5em;
    }
    .form-group-div{
        flex-wrap: wrap;
    }
    .F_T4RIF>div{
        width: 100%;
        flex-direction: column;
    }

    .F_T5{
        flex-direction: column;
        width: 100%;
    }

    .form-group>h1{
        font-size: 1em;
    }
    .form-group>h1>span{
        font-size: 1.3em !important;
    }

    .form-div-name{
        width: 100%;
    }

    .table-div>.input-div{
        flex-direction: column ;
        
    }

    .table-input{
        flex-direction: column;
    }

    .input-div>.table-txt>p{
        text-align: center;
    }
    .input-div>.table-txt{
        /* min-width: 41%; */
        justify-content: center;
    }

    .table-date{
        flex-direction: column;
        
    }

    .table-card{
        border: 1px solid var(--border);
        padding: 1em;
    }

    

}

@media screen and (min-width: 530px){
    .form-group>h1{
        font-size: 1.5em;
    }
    .form-group>h1>span{
        font-size: 1.5em;
    }
}

@media screen and (min-width: 790px){
    .form-group-div{
        flex-wrap: nowrap;
    }
    .F_T5{
        flex-direction: column;
        width: 100%;
    }

    .form-group-outer {
        flex-direction: row;
    }
    .form-group-div{
        flex-direction: row;
    }

    .form-div-name{
        width: 100%;
    }

    .form-group-table{
        flex-direction: column !important;
    }

    .table-input{
        flex-direction: row;
    }

    .table-date{
        flex-direction: row;
    }

    .table-card{
        border: none;
        padding: 0;
        margin: 0;
    }
    
}

@media screen and (min-width: 1071px){
    .table-div,.table-head{
        flex-wrap: nowrap;
    }
    .F_T4RIF>div{
        flex-direction: row;
    }

    .table-txt-total{
        display: flex;
    }

    .table-head-txt{
        display: none;
    }

    .table-head{
        display: flex;
    }

    .F_T5{
        flex-direction: row;
        width: 100%;
    }
    
}

@media screen and (min-width: 1200px){
    .form-group-outer {
        flex-direction: column;
    }

    .form-group-div{
        flex-direction: row;
    }

    .table-div>.input-div{
        flex-direction: row ;
    }

    .input-div input, .input-div select{
        /* max-width: 18em; */
        width: 100%;
    }

    .input-div>.table-txt>p{
        min-width: 41%;
        text-align: right;
    }
    .input-div>.table-txt>p{
        text-align: right;
    }
    .input-div>.table-txt{
        /* min-width: 41%; */
        justify-content: flex-end;
    }
}