.form-group{
    width: 100%;
    background: white;
    padding: 30px 20px;
    /* border: 1px solid var(--border-color); */
    /* box-shadow: 0 0 20px -4px rgba(0,0,0,0.2); */
    border-radius: 0.1em;
    -webkit-border-radius: 0.1em;
    -moz-border-radius: 0.1em;
    -ms-border-radius: 0.1em;
    -o-border-radius: 0.1em;
}

.inner-group{
    width: 100%;
    gap: 1em;
    flex-wrap: nowrap;
}

input{
    width: 100%;
}

.form-btn-group{
    width: 100%;
}

.nav-list{
    width: 100%;
    margin-bottom: -0.1em;
    background: var(--txt-color-light);
    box-shadow: var(--shadow);
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em;

}

.form-container{
    padding: 1em;
}

.body>.nav-list>ul{
    
    background: var(--txt-color-light);
    /* padding: 1em; */
    /* z-index: 999; */
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em;
    overflow: hidden;
}

.body>.nav-list>ul>li{
    /* padding: 0.5em 0em; */
    /* width: 100%; */
}

.body>.nav-list>ul>li>a{
    width: 100%;
    display: flex;
    padding: 0.5em 1em;
    color: var(--sec-color);
    justify-content: center;
}

.nav-list>ul>li>.active{
    /* background: var(--sec-col); */
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2) inset;
    color: var(--sec-color);
}


@media only screen and (min-width: 100px){
    .inner-group{
        flex-direction: column;
    }
    .body>.nav-list>ul,.body>.nav-list>ul>li,.body>.nav-list>ul>li>a{
        width: 100%;
        font-size: 0.95em;
    }
}

@media only screen and (min-width: 550px){
    .inner-group{
        flex-direction: column;
    }
    .body>.nav-list>ul,.body>.nav-list>ul>li,.body>.nav-list>ul>li>a{
        width: fit-content;
        font-size: 1em;
    }

}

@media only screen and (min-width: 700px){
    .inner-group{
        flex-direction: row;
    }
}
