.text-link{
  color: rgb(49, 137, 219);
  text-decoration: none;
}

.table{
  border-collapse: collapse;
}
.table td{
  border:1px solid grey;
  padding: 5px;
  text-align: center;
  margin:15px auto
}
.table .td[bold]{
  font-weight: bold;
}