.svgTxtCard-section{
    
    margin: 5em 0em;

}
.svgTxtCard-txt-div>h2{
    color: var(--grey);
}
/* .svgTxtCard-div{
    background: var(--txt-color-light);
    padding:  3em 5em;
    box-shadow: var(--shadow);
    border-radius: 2em;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    -ms-border-radius: 2em;
    -o-border-radius: 2em;
} */

.svgTxtCard-txt-div>ul>li{
    position: relative;
    padding: 0.5em;
    padding-left: 2em;

}

.svgTxtCard-txt-div>ul>li::before{
    content: '\2713';
    width: 2em;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--border);
    position: absolute;
    left: 0;
}

@media only screen and (min-width: 100px){

    .svgTxtCard-div{
        flex-direction: column-reverse !important;
    }

    .svgTxtCard-txt-div>ul{
        width: 100%;
    }

    .svgTxtCard-div{
        padding: 3em 1em !important;
        gap: 3em;
    }
}

@media only screen and (min-width: 800px){

    .svgTxtCard-div{
        flex-direction: row-reverse !important;
    }

    .svgTxtCard-div{
        padding: 3em 2em !important;
        gap: 1em;
    }
}

@media only screen and (min-width: 1200px){

    .svgTxtCard-div{
        padding: 3em 5em !important;
    }
}
